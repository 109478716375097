import { IStaticPageItem } from '@ui';

export const data: IStaticPageItem[] = [
  {
    title: 'Our Story',
    titleStyle: '',
    subTitle: '',
    description:
      'Prontoclass is co-founded by David and Mark. The journey began in Vietnam, where we shared EFL classes and soon discovered a common interest in Ed Tech. We’re always looking for ways to innovate the classroom beyond arbitrarily ‘using technology’, and both felt that there was a need to better utilise technology to provide more tailored activities for language learners. Hence, Prontoclass was formed in 2024, specialising in games and activities for language learners in a live classroom setting.',
    background: '#83C0EC',
    blocks: []
  },
  {
    title: 'Our Vision',
    titleStyle: '',
    subTitle: '',
    description:
      'Some of our favourite activities in the past have come from cutups. A common part of EFL teaching is encouraging collaboration, group work, and communication. However, they can be rather time-consuming to set up and can eat into precious lesson prep time. Furthermore, there has been a shift from paper handouts to digital alternatives since the COVID-19 pandemic. This has been a positive shift overall but has meant that traditional cutup games and activities have been sidelined. Prontoclass hopes to address this gap by bringing your favourite traditional EFL activities, which usually involve cutups, to the classroom in a digital format. Whether these are action-packed speaking games, such as taboo, or tasks that require more concentration, such as paragraph cutups, Prontoclass brings these classic activities to the modern, paperless classroom!',
    background: '',
    blocks: []
  },
  {
    title: 'Our Games',
    titleStyle: '',
    subTitle: '',
    description:
      'Prontoclass currently boasts 3 exciting games that students of all ages and levels can play! Taboo is a vocabulary game where students must describe a word to their teammates without saying banned words! Hotseat is a frantic game where you shout definitions of a word to your teammate who is in the ‘hotseat’ and has to guess the answer faster than their opponent. Jumble is a game where students have to rearrange a text and put the pieces in the correct order faster than their opponents! All the games require teamwork, collaboration, and communication. A competitive element also boosts engagement as teams compete to win!',
    background: '',
    blocks: []
  }
];
