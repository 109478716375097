import React, { memo } from 'react';

import { data } from './data';

import TemplateHeader from '../TemplateHeader';
import TemplateLayout from '../TemplateLayout';

const TermsCondition = () => {
  return (
    <>
      <TemplateHeader
        title="Terms & Conditions"
        description={`
          Last updated: October 23, 2024<br/><p style="margin-top:8px">Please read these terms and conditions carefully before using Our Service.</p>
          `}
      />

      <TemplateLayout data={data} />
    </>
  );
};

export default memo(TermsCondition);
