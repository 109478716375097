import React, { memo } from 'react';

import { data } from './data';

import TemplateLayout from '../TemplateLayout';

const AboutUs = () => {
  return <TemplateLayout data={data} keepSpaceForEmptyElement />;
};

export default memo(AboutUs);
