import React, { memo } from 'react';
import { KColors, KContainer, KDims, KLabel, KSpacingValue } from 'uikit';

interface IProps {
  title: string;
  description: string;
}

const TemplateHeader = ({ title, description }: IProps) => {
  const isMobile = KDims.isMobile();

  return (
    <div
      style={{
        backgroundImage: isMobile
          ? 'url(/images/template-header.png)'
          : 'url(/images/template-header-desktop.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: isMobile ? 160 : 250,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <KContainer.View paddingH={'2rem'} justifyContent="center" row={false}>
        <KLabel.Paragraph
          color={KColors.white}
          style={{
            fontSize: isMobile ? 24 : 56,
            fontWeight: 'bold',
            marginTop: isMobile ? 0 : -KSpacingValue['0.75rem']
          }}
          numberOfLines={1}
        >
          {title}
        </KLabel.Paragraph>
        <KLabel.Paragraph
          style={{
            fontSize: isMobile ? 16 : 20
          }}
          color={KColors.white}
          dangerouslySetInnerHTML={{
            __html: description
          }}
          marginT={isMobile ? '0.5rem' : '1rem'}
          numberOfLines={3}
        />
      </KContainer.View>
    </div>
  );
};

export default memo(TemplateHeader);
