import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
import React, { memo } from 'react';

interface Props extends Grid2Props {}

const KGridItem = (props: Props) => {
  const { sx, ...otherProps } = props;

  return <Grid sx={{ display: 'grid', ...sx }} {...otherProps} />;
};

KGridItem.defaultProps = {};

KGridItem.displayName = 'KGrid.Item';

export default memo(KGridItem);
