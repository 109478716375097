import React, { memo } from 'react';

import { data } from './data';

import TemplateHeader from '../TemplateHeader';
import TemplateLayout from '../TemplateLayout';

const Privacy = () => {
  return (
    <>
      <TemplateHeader
        title="Privacy Policy"
        description={'Last updated: October 23, 2024'}
      />
      <TemplateLayout data={data} />
    </>
  );
};

export default memo(Privacy);
