import React, { memo } from 'react';
import { KGrid, KImage, KLabel } from 'uikit';

import { data } from './data';

import TemplateLayout from '../../TemplateLayout';

const FAQs = () => {
  return (
    <>
      <TemplateLayout data={data} withSpace={false} />

      <KGrid.Container sx={{ display: 'flex' }} noPadding={false}>
        <KGrid.Item xs={12} sm={6}>
          <KImage.Base source="/images/static/features_taboo.png" />

          <KLabel.Paragraph marginT="0.75rem">
            This is the screen that will display on the device of a describer.
            Please note: In hotseat, there is no banned words list.
          </KLabel.Paragraph>
        </KGrid.Item>

        <KGrid.Item xs={12} sm={6}>
          <KImage.Base source="/images/static/features_jumble.png" />

          <KLabel.Paragraph marginT="0.75rem">
            This is the screen that will display on the device of a guesser.
          </KLabel.Paragraph>
        </KGrid.Item>
      </KGrid.Container>
    </>
  );
};

export default memo(FAQs);
