import { IStaticPageItem } from '@ui';

export const data: IStaticPageItem[] = [
  {
    title: 'Taboo',
    description:
      'Taboo is a great game for your learners to develop their vocabulary range and encourages them to think of some creative ways to describe a word. Great for revision of the unit’s vocabulary or for thinking of synonyms to convey the meaning of a word they’ve recently learnt. The catch is that each keyword that students have to describe comes with a list of banned ‘taboo’ words. If the describer says a banned word, their team loses a point! Students will be randomly selected to be the describer and everyone will have a chance to do it at least once!',
    background: '',
    blocks: [],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description: '',
    background: '',
    blocks: [
      {
        image: 'features_taboo.png',
        background: '',
        description: [],
        subTitle: '',
        title: ''
      }
    ],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description: '',
    background: '',
    blocks: [
      {
        image: 'features_hotseat.png',
        background: '',
        description: [],
        subTitle: '',
        title: ''
      }
    ],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: 'Hotseat',
    description:
      'A classic vocab revision game where students compete in teams to describe a word to their teammate who is in the hotseat. The first student to shout out the word in the hotseat gets a point for their team. Every student should have a chance to be in the hotseat as this will test them on the vocabulary they have recently learnt. If you create enough words in the list, there might be enough for every student to have 2 goes!',
    background: '',
    blocks: [],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: 'Jumble',
    description:
      'Some of the best ways for students to analyse a piece of text is to rearrange it from cutups. Jumble facilitates this by giving a piece of text e.g. word, sentence or paragraph to students which needs to be put in the correct order by collaborating with other students. Each device will receive a different piece of the puzzle, so students will need to work with each other and look at all devices in order to work out the correct order.',
    background: '',
    blocks: [],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description: '',
    background: '',
    blocks: [
      {
        image: 'features_jumble.png',
        background: '',
        description: [],
        subTitle: '',
        title: ''
      }
    ],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: 'More coming soon...',
    description: 'Watch this space!',
    background: '',
    blocks: [],
    subTitle: '',
    titleStyle: ''
  }
];
