import { IStaticPageItem } from '@ui';

export const data: IStaticPageItem[] = [
  {
    title: 'FAQs',
    description: '',
    background: '',
    blocks: [],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description: '',
    background: '#83C0EC',
    blocks: [
      {
        title: 'Q. How can my students join a game when I launch it?',
        background: '#83C0EC',
        description: [],
        image: '',
        subTitle: ''
      }
    ],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description:
      'A. When you launch the game, a screen will appear showing the link and a game code. This can be projected to the whole class. There is also a QR code which students can scan to join the game.',
    background: '',
    blocks: [],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description: '',
    background: '#83C0EC',
    blocks: [
      {
        title:
          'Q. How do I arrange the groups once students have joined the game?',
        background: '#83C0EC',
        description: [],
        image: '',
        subTitle: ''
      }
    ],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description:
      'A. When students join a game, they will automatically be put in random groups. However, they can move themselves manually to other groups by clicking on ‘join team’. The teacher can also move them manually to other groups by clicking on the following button on the teacher’s screen:<br/><br/> Please note: Once all teams are full (based on the maximum number of students per team you input in the game setup), students will no longer be able to join a team. Instead, they will be moved to an unassigned ‘observations’ area and won’t be able to participate. You can always change the maximum number of students per team before launching the game, if this happens.',
    background: '',
    blocks: [],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description: '',
    background: '#83C0EC',
    blocks: [
      {
        title: 'Q. What happens if a student loses connection during the game?',
        background: '#83C0EC',
        description: [],
        image: '',
        subTitle: ''
      }
    ],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description:
      'A. Students can refresh the page, and enter the same game code and same username as they used previously. Then they click ‘reconnect’. This will take them back into the game and place them in the same team as they were in previously.<br/><br/> Please note: The game code and username must be exactly the same as the one they signed up with previously in order to reconnect successfully.  They must make sure to click ‘reconnect’ and not ‘join’ when this happens.',
    background: '',
    blocks: [],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description: '',
    background: '#83C0EC',
    blocks: [
      {
        title:
          'Q. A student has been found to have broken some rules during the game, but the points were mistakenly awarded. Can I adjust the scores ?',
        background: '#83C0EC',
        description: [],
        image: '',
        subTitle: ''
      }
    ],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description:
      'A. Scores can be adjusted manually for all teams once the game has been completed on the ‘score discussion’ page. This gives the teacher a chance to rectify any points mistakenly awarded / deducted.',
    background: '',
    blocks: [],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description: '',
    background: '#83C0EC',
    blocks: [
      {
        title:
          'Q. What happens if I need to end the game earlier than expected and before all the words have been played?',
        background: '#83C0EC',
        description: [],
        image: '',
        subTitle: ''
      }
    ],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description:
      'A. A game can be ended at any point by clicking on the ‘emergency end game’ button. Once this happens, it will go straight to the game review and score discussion game, keeping a track of the score up to the point that you ended the game.',
    background: '',
    blocks: [],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description: '',
    background: '#83C0EC',
    blocks: [
      {
        title: 'Q. How do I know which student has been selected in taboo?',
        background: '#83C0EC',
        description: [],
        image: '',
        subTitle: ''
      }
    ],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description:
      'A. The student who has the keyword and the banned words list on their screen is the one describing the word to their teammates. The students who have the display saying ‘try your best’ are the ones trying to guess the word. Please note: If playing chaotic mode, students from the opposing team will also be able to see the keyword and banned words list as they need to be able to see if a banned word has been used and to pause the game to award / deduct points.',
    background: '',
    blocks: [],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description: '',
    background: '#83C0EC',
    blocks: [
      {
        title: 'Q. How do I know which student has been selected in hotseat?',
        background: '#83C0EC',
        description: [],
        image: '',
        subTitle: ''
      }
    ],
    subTitle: '',
    titleStyle: ''
  },
  {
    title: '',
    description:
      'A. The student who has the display saying ‘try your best’ on their screen is the one in the hotseat. Those students should move into the hotseat, ideally at the front of the class. Please note: all the other students in the class will be able to see the word that needs to be described to the student in the hotseat.',
    background: '',
    blocks: [],
    subTitle: '',
    titleStyle: ''
  }
];
