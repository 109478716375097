import { IconGrid } from '@assets/icons/components/IconGrid';
import { IS_TEACHER, STATIC_PAGE_PATHS, authRef } from '@constants';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Portal } from 'containers/Portal';
import { withAuth } from 'hocs/withAuth';
import { useGoogleVerifyWithCode, useUser } from 'hooks';
import React, { memo, useMemo, useRef } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import trans from 'translation';
import {
  KButton,
  KColors,
  KContainer,
  KDims,
  KImage,
  KLabel,
  useMount
} from 'uikit';
import { UIUtils } from 'utils';

import Auth from './Auth';
import Sidebar from './Sidebar';
import FeatureSidebar from './Sidebar/Feature';
import { ISidebarInstance } from './Sidebar/helpers';

const AppLayout = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const location = useLocation();

  const isStaticPage = STATIC_PAGE_PATHS.includes(location.pathname);

  const { user } = useUser();

  const sidebarRef = useRef<ISidebarInstance>(null);
  const featureSidebarRef = useRef<ISidebarInstance>(null);

  const learnMoreRef = useRef<HTMLDivElement>(null);

  const renderLogo = useMemo(() => {
    return (
      <KImage.Base
        source="/images/logo.webp"
        height={42}
        width={(272 * 42) / 84}
        dp="flex"
        center
        noHover
        onPress={() => navigate('/')}
      />
    );
  }, [navigate]);

  const isMobile = KDims.isMobile();

  const data = useMemo(() => {
    const eData = [
      {
        key: 'faqs',
        title: 'common.faqs'
      }
      // {
      //   key: 'taboo',
      //   title: 'common.taboo'
      // },
      // {
      //   key: 'hotseat',
      //   title: 'common.hotseat'
      // },
      // {
      //   key: 'jumble',
      //   title: 'common.jumble'
      // }
    ];

    return [
      {
        key: '/',
        title: 'common.home',
        onPress: () => navigate('/')
      },
      {
        key: '/features',
        title: 'common.features',
        onPress: () => window.open('/features', '_blank')
      },
      {
        key: '/about-us',
        title: 'common.about_us',
        onPress: () => window.open('/about-us', '_blank')
      },
      {
        key: '/learn-more',
        title: 'common.learn_more',
        onPress: () =>
          UIUtils.popper.open({
            anchorEl: learnMoreRef.current,
            placement: 'bottom-start',
            content: () => (
              <KContainer.View
                background={KColors.white}
                brW={1}
                brC="#e2e8f0"
                br="2x"
                minW={150}
                marginT="0.25rem"
                paddingV="0.5rem"
                paddingH="0.25rem"
                dp="flex"
              >
                {eData.map(e => {
                  return (
                    <KButton.Transparent
                      key={`learn-more-${e.key}`}
                      title={trans(e.title)}
                      kind="gray"
                      onPress={() =>
                        window.open(`/learn-more/${e.key}`, '_blank')
                      }
                      height={36}
                      alignItems="flex-start"
                      width="100%"
                      size="sm"
                    />
                  );
                })}
              </KContainer.View>
            ),
            sx: { zIndex: 1301 },
            touchOutsideToDismiss: true
          })
      }
    ];
  }, [navigate]);

  const { mutate } = useGoogleVerifyWithCode();

  useMount(() => {
    if (
      IS_TEACHER &&
      searchParams.get('state') === 'sign-in' &&
      searchParams.get('code')
    ) {
      // console.log(searchParams.get('code') as string);
      mutate(searchParams.get('code') as string);
    }
  });

  return (
    <KContainer.View position="relative">
      <KContainer.View
        background={KColors.white}
        dp="flex"
        flex={1}
        minH={'100vh'}
      >
        <AppBar position="fixed" elevation={0}>
          <Toolbar
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'space-between',
              flexShrink: 0,
              // borderRadius: '999px',
              bgcolor:
                theme.palette.mode === 'light'
                  ? KColors.white
                  : 'rgba(0, 0, 0, 0.4)',
              // backdropFilter: 'blur(24px)',
              // maxHeight: 40,
              // border: '1px solid',
              paddingLeft: '1.5rem !important',
              paddingRight: '1.5rem !important',
              minHeight: '56px !important'
              // borderColor: 'divider',
              // boxShadow:
              //   theme.palette.mode === 'light'
              //     ? '0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)'
              //     : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)'
            })}
          >
            <KContainer.View sx={{ display: { md: 'none', xs: 'block' } }}>
              <KContainer.Touchable
                onPress={() => featureSidebarRef.current?.onOpen()}
                width={24}
              >
                <IconGrid fill={KColors.white} />
              </KContainer.Touchable>
            </KContainer.View>

            <KContainer.View
              flex
              center
              sx={{
                display: { md: 'none !important', xs: 'flex !important' }
              }}
            >
              {renderLogo}
            </KContainer.View>

            <KContainer.View
              sx={{
                display: { xs: 'none !important', md: 'block !important' }
              }}
            >
              {renderLogo}
            </KContainer.View>

            <KContainer.View
              flex
              row
              center
              gap="3rem"
              sx={{
                display: { xs: 'none !important', md: 'flex !important' }
              }}
            >
              {data.map(i => {
                return (
                  <KContainer.Touchable
                    ref={i.key === '/learn-more' ? learnMoreRef : undefined}
                    key={i.key}
                    onPress={i.onPress}
                  >
                    <KLabel.Text>{trans(i.title)}</KLabel.Text>
                  </KContainer.Touchable>
                );
              })}
            </KContainer.View>

            {/* <KInput.Search /> */}

            {IS_TEACHER &&
              (!!user ? (
                isMobile ? (
                  <KButton.Icon
                    icon="Menu"
                    tight
                    onPress={() => sidebarRef.current?.onOpen()}
                  />
                ) : (
                  <KContainer.View row alignItems gap="0.75rem">
                    <KImage.Avatar size="lg" />

                    <KContainer.View>
                      <KLabel.Paragraph typo="TextMdNormal">
                        {user?.username}
                      </KLabel.Paragraph>

                      <KLabel.Text
                        marginT="0.25rem"
                        typo="TextSmNormal"
                        color={KColors.primary.normal}
                      >
                        {trans('common.teacher')}
                      </KLabel.Text>
                    </KContainer.View>
                  </KContainer.View>
                )
              ) : (
                <KContainer.View row alignItems>
                  <KContainer.Touchable onPress={() => authRef.current?.open()}>
                    <KLabel.Text color={KColors.black}>
                      {trans('common.login')}
                    </KLabel.Text>
                  </KContainer.Touchable>

                  <KContainer.View
                    height={12}
                    width={1}
                    background={KColors.black}
                    marginH="0.5rem"
                  />

                  <KContainer.Touchable
                    onPress={() => authRef.current?.open(1)}
                  >
                    <KLabel.Text color={KColors.black}>
                      {trans('common.register')}
                    </KLabel.Text>
                  </KContainer.Touchable>
                </KContainer.View>
              ))}
          </Toolbar>
        </AppBar>

        {!isMobile && IS_TEACHER ? (
          <KContainer.View row paddingT="3.5rem">
            {!!user && !isStaticPage && <Sidebar isDrawer={false} />}

            <KContainer.View
              flex
              marginH={
                !user
                  ? undefined
                  : isStaticPage
                  ? 0
                  : window.innerWidth < 960
                  ? '2rem'
                  : window.innerWidth < 1140
                  ? '4rem'
                  : '8rem'
              }
            >
              <Outlet />
            </KContainer.View>
          </KContainer.View>
        ) : (
          <KContainer.View paddingT="3.5rem" flexG={1}>
            <Outlet />
          </KContainer.View>
        )}

        <KContainer.View
          background={KColors.primary.normal}
          // height={390}
          style={{ flexBasis: 223 }}
          // position="fixed"
          // className="bottom-0 left-0 right-0"
        >
          <KContainer.View
            row
            paddingT="1.5rem"
            paddingB="1rem"
            paddingH={32 as any}
          >
            <KContainer.View flex>
              <KLabel.Paragraph typo="TextMdBold" color={KColors.white}>
                {trans('common.products')}
              </KLabel.Paragraph>

              <KLabel.Paragraph
                typo="TextMdNormal"
                color={KColors.white}
                marginT="0.75rem"
                onPress={() => window.open('/about-us', '_blank')}
              >
                {trans('common.about_us')}
              </KLabel.Paragraph>

              <KLabel.Paragraph
                typo="TextMdNormal"
                color={KColors.white}
                marginT="1rem"
                onPress={() => window.open('/features', '_blank')}
              >
                {trans('common.features')}
              </KLabel.Paragraph>

              <KLabel.Paragraph
                typo="TextMdNormal"
                color={KColors.white}
                marginT="1rem"
                onPress={() => window.open('/pricing', '_blank')}
              >
                {trans('common.pricing')}
              </KLabel.Paragraph>
            </KContainer.View>

            <KContainer.View flex>
              <KLabel.Paragraph typo="TextMdBold" color={KColors.white}>
                {trans('common.contact_information')}
              </KLabel.Paragraph>

              <KLabel.Paragraph
                typo="TextMdNormal"
                color={KColors.white}
                marginT="1rem"
              >
                Info@prontoclass.com
              </KLabel.Paragraph>
            </KContainer.View>
          </KContainer.View>

          <KContainer.View
            height={1}
            width={'100%'}
            background={KColors.white}
          />

          <KContainer.View paddingV="0.75rem" paddingH="1.5rem" center>
            <KLabel.Text color={KColors.white} typo="TextXsNormal">
              2024 ProntoClass. All Rights Reserved.
            </KLabel.Text>
          </KContainer.View>
        </KContainer.View>
      </KContainer.View>

      <Auth ref={authRef} />

      {!!user && isMobile && <Sidebar ref={sidebarRef} />}

      {isMobile && <FeatureSidebar ref={featureSidebarRef} />}

      <Portal />
    </KContainer.View>
  );
};

export default memo(withAuth(AppLayout));
