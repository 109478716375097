import { E_GAME_MODES } from '@constants/gameConfigs';
import { IWord } from '@dto';
import { CSSProperties } from '@mui/styles';
import { useGameContext } from 'context/GameContext';
import React, { memo, useCallback, useMemo, useState } from 'react';
import trans from 'translation';
import { KContainer, KLabel, KButton, KSpacingValue } from 'uikit';

const ReviewingJumble = () => {
  const { roomInfo, toggleJumbleStatistics, gameMode } = useGameContext();

  const { teams = [] } = roomInfo || {};

  const [index, setIndex] = useState(0);

  const wordText = useMemo(() => {
    switch (gameMode) {
      case E_GAME_MODES.WORD.id:
        return 'common.word';

      case E_GAME_MODES.SENTENCE.id:
        return 'common.sentence';

      case E_GAME_MODES.PARAGRAPH.id:
        return 'common.paragraph';

      default:
        return 'common.word';
    }
  }, [gameMode]);

  const fadeStyles = useCallback((isVisible: boolean, translateX?: boolean) => {
    const result = {
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? 'scale(1)' : 'scale(1)',
      transition: 'opacity 0.3s ease, transform 0.3s ease'
    } as CSSProperties;

    if (translateX) {
      result.transform += ` translateX(${
        isVisible ? '-16px' : '-96px'
      }) rotate(${isVisible ? '0deg' : '360deg'})`;
    }

    return result;
  }, []);

  const slideStyle = {
    display: 'flex',
    transition: 'transform 0.2s ease',
    transform: `translateX(calc(-${index * 100}% - ${
      index === 0 ? 0 : KSpacingValue['1.5rem']
    }px))`
  };

  const renderTeamWords = useCallback(
    (teamId: string, words: IWord[]) => {
      return (words ?? [])
        .sort((a, b) => (a.id ?? 0) - (b.id ?? 0))
        .map((i, idx) => {
          const isSuccess = Number(i.score) > 0;

          return (
            <KContainer.View
              key={`${teamId}-word-${i.id}`}
              row
              marginT={idx === 0 ? 0 : '1.5rem'}
              gap="1rem"
            >
              <KContainer.View dp="flex" alignItems width={50}>
                <KLabel.Paragraph typo="TextNmBold">
                  {idx === 0 ? trans('common.answer') : ''}
                </KLabel.Paragraph>

                <KLabel.Text
                  typo="TextMdBold"
                  marginT={idx === 0 ? '1.25rem' : '2.5rem'}
                >
                  {i.order === 0 ? '-' : i.order}
                </KLabel.Text>
              </KContainer.View>

              <KContainer.View flex>
                <KLabel.Paragraph typo="TextNmBold" marginH={'0.75rem'}>
                  {trans(wordText)} {idx + 1}
                </KLabel.Paragraph>

                <KContainer.View
                  brW={1}
                  br={'2x'}
                  brC={isSuccess ? '#27AE60' : '#F77777'}
                  background={isSuccess ? '#E2F8EB' : '#FFECED'}
                  padding={'0.75rem'}
                  marginT={'0.5rem'}
                >
                  <KLabel.Text>{i.texts[0]}</KLabel.Text>
                </KContainer.View>
              </KContainer.View>
            </KContainer.View>
          );
        });
    },
    [wordText]
  );

  const len = teams.length - 1;

  return (
    <KContainer.View marginH={'1.5rem'}>
      <KContainer.View
        br={'4x'}
        background="#F7F7F7"
        padding={'1.5rem'}
        overflowY="scroll"
        maxH={400}
      >
        <KContainer.View marginB={'1.5rem'} alignItems>
          <KLabel.Text textAlign typo="TextLgBold" flex>
            {teams[index].name}
          </KLabel.Text>
        </KContainer.View>

        <KContainer.View row style={slideStyle}>
          {(teams ?? []).map((team, teamIndex) => {
            return (
              <KContainer.View
                key={team.id}
                style={{
                  minWidth: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
                marginL={teamIndex === 0 ? 0 : '1.5rem'}
              >
                {renderTeamWords(team.id, team.words)}
              </KContainer.View>
            );
          })}
        </KContainer.View>
      </KContainer.View>

      <KContainer.View
        row
        alignItems
        marginT={'1.5rem'}
        justifyContent="flex-end"
      >
        <div style={fadeStyles(index > 0, true)}>
          <KButton.Solid
            width={100}
            title={trans('common.back')}
            onPress={() => setIndex(p => Math.min(p - 1, 0))}
          />
        </div>

        <div style={fadeStyles(true)}>
          <KButton.Solid
            width={100}
            title={trans('common.next')}
            onPress={() => {
              if (index === len) {
                toggleJumbleStatistics();
                return;
              }
              setIndex(p => Math.min(p + 1, len));
            }}
          />
        </div>
      </KContainer.View>
    </KContainer.View>
  );
};

ReviewingJumble.displayName = 'Containers.InGame.Reviewing.Jumble';

export default memo(ReviewingJumble);
