import React, { memo } from 'react';

import { data } from './data';

import TemplateLayout from '../TemplateLayout';

const Features = () => {
  return <TemplateLayout data={data} withDesktopVersion />;
};

export default memo(Features);
