import React, { memo } from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import trans from 'translation';
import { KColors, KContainer, KDims, KLabel } from 'uikit';

const HEIGHT = 220;

const GameCode = () => {
  const { roomId = '' } = useParams();

  const isMobile = KDims.isMobile();

  return (
    <KContainer.View
      row
      alignItems
      gap="1rem"
      marginT="1.75rem"
      marginH={isMobile ? '1.25rem' : undefined}
    >
      <KContainer.View
        br="5x"
        brW={1}
        brC={KColors.primary.normal}
        padding="1rem"
        dp="flex"
        alignItems
        justifyContent="space-between"
        height={HEIGHT}
        flex
      >
        <KLabel.Paragraph typo="TextXNmBold" color={KColors.primary.normal}>
          {process.env.REACT_APP_STUDENT_URL?.split('//')[1]}
        </KLabel.Paragraph>

        <KLabel.Paragraph typo="H0" color={KColors.primary.normal}>
          {roomId}
        </KLabel.Paragraph>

        <KLabel.Paragraph
          typo="TextNmBold"
          textTransform="uppercase"
          color={KColors.primary.normal}
        >
          {trans('common.game_code')}
        </KLabel.Paragraph>
      </KContainer.View>

      <KContainer.View
        br="5x"
        brW={1}
        brC={KColors.primary.normal}
        padding="1rem"
        dp="flex"
        alignItems
        height={HEIGHT}
        flex
      >
        <KContainer.View dp="flex" flex alignItems>
          <QRCode
            size={HEIGHT - 70}
            value={`${process.env.REACT_APP_STUDENT_URL}?code=${roomId}`}
          />
        </KContainer.View>

        <KLabel.Text
          typo="TextNmBold"
          textTransform="uppercase"
          color={KColors.primary.normal}
        >
          {trans('common.game_qr_code')}
        </KLabel.Text>
      </KContainer.View>
    </KContainer.View>
  );
};

export default memo(GameCode);
