import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
import React, { memo, useMemo } from 'react';

interface Props extends Grid2Props {
  noPadding?: boolean;
}

const KGridContainer = (props: Props) => {
  const { noPadding, style, sx, ...otherProps } = props;

  const commonStyle = useMemo(() => {
    const result = {
      padding: '0 12px'
    };
    if (noPadding) {
      result.padding = '0';
    }
    return { ...result, ...style };
  }, [noPadding, style]);

  return (
    <Grid
      sx={{ m: 0, width: 'unset', display: 'grid', ...sx }}
      {...otherProps}
      style={commonStyle}
    />
  );
};

KGridContainer.defaultProps = {
  noPadding: true,
  container: true,
  spacing: 6,
  alignItems: 'center'
};

KGridContainer.displayName = 'KGrid.Container';

export default memo(KGridContainer);
