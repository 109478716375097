import {
  IAuthInstance,
  WithAlertDialogProps,
  WithPopperProps,
  WithPopupProps,
  WithSnackBarProps
} from '@ui';
import { createRef } from 'react';

import { ROLE } from './enum';

export * from './query';
export * from './enum';

export const IS_TEACHER =
  (process.env.REACT_APP_USER_ROLE as ROLE) === ROLE.TEACHER;

export const alertRef = createRef<WithAlertDialogProps>();
export const popupRef = createRef<WithPopupProps>();
export const popperRef = createRef<WithPopperProps>();
export const snackBarRef = createRef<WithSnackBarProps>();

export const authRef = createRef<IAuthInstance>();

export const PAGE_DEFAULT = 0;
export const PAGE_SIZE_DEFAULT = 20;
export const PAGE_SIZE_DEFAULT_MAX = 2147483647;

export const FOLDER_MAP_COLOR = {
  '#567df4': '#415eb6',
  '#ffde6c': '#ffb110',
  '#f45656': '#ac4141',
  '#34dede': '#23b0b0',
  '#ffc2cd': '#fb95a6',
  '#f2c2ac': '#a16929'
};

export const VOLUME_THRESHOLD = {
  HIGH: 1.0,
  MEDIUM: 0.5,
  LOW: 0.2,
  OFF: 0
};

export const STATIC_PAGE_PATHS = [
  '/about-us',
  '/features',
  '/privacy',
  '/terms-conditions',
  '/learn-more/faqs',
  '/learn-more/taboo',
  '/learn-more/hotseat',
  '/learn-more/jumble'
];
