import { IconArrowRight } from '@assets/icons/components/IconArrowRight';
import { IconX } from '@assets/icons/components/IconX';
import { EventName, IS_TEACHER } from '@constants';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import AppEvent from 'core/event';
import { useUser } from 'hooks';
import { useBoolean } from 'hooks/core/useBoolean';
import { isEmpty } from 'lodash';
import compact from 'lodash/compact';
import {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import trans from 'translation';
import { KColors, KContainer, KImage, KLabel } from 'uikit';

import { ISidebarInstance, ISidebarItem } from './helpers';

const FeatureSidebar = forwardRef<ISidebarInstance, any>((_, ref) => {
  const { user } = useUser();

  const { value: isOpen, setTrue, setFalse } = useBoolean(false);

  const [activeKey, setActiveKey] = useState('');

  useImperativeHandle(ref, () => ({
    onOpen: setTrue
  }));

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const data: ISidebarItem[] = useMemo(() => {
    const _data = [
      {
        key: '/',
        title: 'common.home',
        onPress: () => navigate('/')
      },
      {
        key: '/features',
        title: 'common.features',
        onPress: () => navigate('/features')
      },
      {
        key: '/about-us',
        title: 'common.about_us',
        onPress: () => navigate('/about-us')
      },
      {
        key: '/learn-more',
        title: 'common.learn_more',
        onPress: () =>
          setActiveKey(activeKey === '/learn-more' ? '' : '/learn-more'),
        children: [
          {
            key: '/learn-more/faqs',
            title: 'common.faqs',
            onPress: () => navigate('/learn-more/faqs')
          }
          // {
          //   key: '/learn-more/taboo',
          //   title: 'common.taboo',
          //   onPress: () => navigate('/learn-more/taboo')
          // },
          // {
          //   key: '/learn-more/hotseat',
          //   title: 'common.hotseat',
          //   onPress: () => navigate('/learn-more/hotseat')
          // },
          // {
          //   key: '/learn-more/jumble',
          //   title: 'common.jumble',
          //   onPress: () => navigate('/learn-more/jumble')
          // }
        ]
      }
    ];

    return IS_TEACHER
      ? compact([
          ..._data,
          !!user
            ? {
                key: 'logout',
                title: 'common.sign_out',
                onPress: () => AppEvent.dispatch(EventName.LOGOUT)
              }
            : undefined
        ])
      : _data;
  }, [activeKey, navigate, user]);

  const renderItem = useCallback(
    (item: ISidebarItem) => {
      const { key, title, onPress, children } = item;
      const isActive = key === pathname;

      const hasSubMenu = !isEmpty(children);
      const isShow =
        hasSubMenu && (activeKey === key || pathname.includes(key));

      return (
        <>
          <KContainer.Touchable
            key={key}
            row
            alignItems
            justifyContent="space-between"
            padding="0.75rem"
            gap="0.5rem"
            br="2x"
            background={isActive ? KColors.primary.normal : undefined}
            onPress={() => {
              !hasSubMenu && setFalse();
              !hasSubMenu && setActiveKey('');
              onPress();
            }}
          >
            <KLabel.Text
              typo="TextXNmNormal"
              flex
              color={
                key === 'logout'
                  ? '#f77777'
                  : isActive
                  ? KColors.white
                  : undefined
              }
              textAlign="left"
            >
              {trans(title)}
            </KLabel.Text>

            {hasSubMenu && (
              <IconButton
                sx={{
                  padding: 0,
                  transform: `rotate(${!isShow ? 0 : 90}deg)`,
                  transition: 'all 0.3s'
                }}
              >
                <IconArrowRight
                  fill={KColors.black}
                  className={'w-[24px] h-[24px] transition-transform'}
                />
              </IconButton>
            )}
          </KContainer.Touchable>

          {isShow &&
            children?.map(i => {
              const _isActive = pathname === i.key;

              return (
                <KContainer.Touchable
                  key={key}
                  row
                  alignItems
                  justifyContent="space-between"
                  padding="0.75rem"
                  gap="0.5rem"
                  br="2x"
                  marginH="1.5rem"
                  background={_isActive ? KColors.primary.normal : undefined}
                  onPress={() => {
                    setFalse();
                    i.onPress();
                  }}
                >
                  <KLabel.Text
                    typo="TextXNmNormal"
                    flex
                    color={_isActive ? KColors.white : undefined}
                    textAlign="left"
                  >
                    {trans(i.title)}
                  </KLabel.Text>
                </KContainer.Touchable>
              );
            })}
        </>
      );
    },
    [pathname, activeKey, setFalse]
  );

  return (
    <>
      {isOpen && (
        <KContainer.View
          width={65}
          height={72}
          position="absolute"
          className="top-0 left-[310px] z-[1201]"
          center
        >
          <KContainer.Touchable onPress={() => setFalse()}>
            <IconX fill="black" width={24} height={24} />
          </KContainer.Touchable>
        </KContainer.View>
      )}

      <Drawer
        anchor="left"
        open={isOpen}
        // onClose={onClose}
        PaperProps={{
          sx: {
            width: 310,
            backgroundColor: KColors.transparent
          }
        }}
        sx={{
          width: 310,
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(252,252,252,0.5)',
            backdropFilter: 'blur(5px)'
          }
        }}
      >
        <KContainer.View
          width="100%"
          height="100%"
          padding="1.5rem"
          background={KColors.white}
        >
          {!!user && (
            <KContainer.View row alignItems gap="0.75rem" marginB="2rem">
              <KImage.Avatar size="lg" />

              <KContainer.View>
                <KLabel.Paragraph typo="TextMdNormal">
                  {user?.username}
                </KLabel.Paragraph>

                <KLabel.Text
                  marginT="0.25rem"
                  typo="TextSmNormal"
                  color={KColors.primary.normal}
                >
                  {trans('common.teacher')}
                </KLabel.Text>
              </KContainer.View>
            </KContainer.View>
          )}

          <KContainer.View gap="0.5rem" dp="flex">
            {data.map(i => renderItem(i))}
          </KContainer.View>
        </KContainer.View>
      </Drawer>
    </>
  );
});

export default memo(FeatureSidebar);
