import { IStaticPageItem } from '@ui';
import { isEmpty } from 'lodash';
import React, { memo, useCallback } from 'react';
import { KColors, KContainer, KDims, KGrid, KImage, KLabel } from 'uikit';

import './index.css';

interface IProps {
  data: Array<IStaticPageItem>;
  keepSpaceForEmptyElement?: boolean;
  withDesktopVersion?: boolean;
  withSpace?: boolean;
  withParent?: boolean;
}

const TemplateLayout = ({
  data,
  keepSpaceForEmptyElement,
  withDesktopVersion,
  withSpace = true,
  withParent = true
}: IProps) => {
  const isMobile = KDims.isMobile();

  const renderBlocks = useCallback(
    (blocks: IStaticPageItem['blocks']) => {
      return blocks.map((b, bIdx) => {
        return (
          <KGrid.Item
            xs={12}
            key={`block-${bIdx}`}
            sx={{ display: 'flex', paddingBottom: 0 }}
          >
            <KContainer.View
              marginH={-12 as any}
              width={isMobile ? undefined : '100%'}
              background={b.background || KColors.white}
            >
              <KGrid.Container rowSpacing={3} sx={{ display: 'flex' }}>
                {(b.title || b.subTitle) && (
                  <KGrid.Item
                    xs={12}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                  >
                    {!!b.title && (
                      <KLabel.Paragraph typo="H2">{b.title}</KLabel.Paragraph>
                    )}

                    {!!b.subTitle && (
                      <KLabel.Paragraph
                        typo="H3"
                        marginT={!!b.title ? '1.25rem' : 0}
                      >
                        {b.subTitle}
                      </KLabel.Paragraph>
                    )}
                  </KGrid.Item>
                )}

                {!isEmpty(b.description) && (
                  <>
                    {b.description.map((d, dIdx) => {
                      if (d.type === 'string') {
                        return (
                          <KGrid.Item
                            xs={12}
                            key={`des-${dIdx}`}
                            sx={{ display: 'flex' }}
                          >
                            <KLabel.Paragraph
                              dangerouslySetInnerHTML={{
                                __html: d.value[0]
                              }}
                              style={{ fontSize: 16 }}
                              marginV="0.5rem"
                            />
                          </KGrid.Item>
                        );
                      }

                      if (d.order) {
                        return (
                          <ol key={`des-${dIdx}`} start={d.start ?? 1}>
                            {d.value.map((v, vIdx) => {
                              return (
                                <li
                                  key={`desVal-${vIdx}`}
                                  dangerouslySetInnerHTML={{
                                    __html: v
                                  }}
                                />
                              );
                            })}
                          </ol>
                        );
                      }

                      return (
                        <ul key={`des-${dIdx}`}>
                          {d.value.map((v, vIdx) => {
                            return (
                              <li
                                key={`desVal-${vIdx}`}
                                dangerouslySetInnerHTML={{
                                  __html: v
                                }}
                              />
                            );
                          })}
                        </ul>
                      );
                    })}
                  </>
                )}

                {b.image && (
                  <KGrid.Item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <KImage.Base
                      source={`/images/static/${b.image}`}
                      width={
                        !isMobile
                          ? (402 * KDims.getWindowSize().width) / 1440
                          : ('100%' as any)
                      }
                    />
                  </KGrid.Item>
                )}
              </KGrid.Container>
            </KContainer.View>
          </KGrid.Item>
        );
      });
    },
    [isMobile]
  );

  return (
    <KGrid.Container sx={{ display: 'flex' }}>
      {data.map((i, idx) => {
        if (!withParent) {
          return renderBlocks(i.blocks);
        }

        return (
          <KGrid.Item
            xs={withDesktopVersion ? (isMobile ? 12 : 6) : 12}
            key={`section-${idx}`}
            sx={{ display: 'flex', paddingLeft: 0, paddingRight: 0 }}
          >
            <KContainer.View
              // marginH={-12 as any}
              marginV={-12 as any}
              width={isMobile ? undefined : '100%'}
              paddingV={withSpace ? '1rem' : 0}
              paddingH="1.25rem"
              background={i.background || KColors.white}
              paddingB={
                withSpace &&
                (keepSpaceForEmptyElement ||
                  idx === data.length - 1 ||
                  (withDesktopVersion && !isMobile))
                  ? '1rem'
                  : !!i.background
                  ? '0.25rem'
                  : 0
              }
            >
              <KGrid.Container sx={{ display: 'flex' }}>
                {!!i.title && (
                  <KGrid.Item
                    xs={12}
                    sx={{
                      display: 'flex',
                      paddingBottom:
                        !i.subTitle && !i.description ? 0 : undefined
                    }}
                  >
                    <KLabel.Paragraph typo="H1">{i.title}</KLabel.Paragraph>
                  </KGrid.Item>
                )}

                {!!i.subTitle && (
                  <KGrid.Item
                    xs={12}
                    sx={{
                      display: 'flex',
                      paddingBottom: !i.description ? 0 : undefined
                    }}
                  >
                    <KLabel.Paragraph typo="H2">{i.subTitle}</KLabel.Paragraph>
                  </KGrid.Item>
                )}

                {!!i.description && (
                  <KGrid.Item xs={12} sx={{ display: 'flex' }}>
                    <KLabel.Paragraph
                      dangerouslySetInnerHTML={{
                        __html: i.description
                      }}
                      style={{ fontSize: 16 }}
                    />
                  </KGrid.Item>
                )}

                {renderBlocks(i.blocks)}
              </KGrid.Container>
            </KContainer.View>
          </KGrid.Item>
        );
      })}
    </KGrid.Container>
  );
};

export default memo(TemplateLayout);
